import Home from "./Home.svelte";
import Portfolio from "./Portfolio.svelte";
import Image from "./Image.svelte";
import Privacy from "./Privacy.svelte";
import NotFound from "./NotFound.svelte";

export default {
	"/": Home,
	"/portfolio": Portfolio,
	"/images/:link": Image,
	"/privacy": Privacy,

	"*": NotFound,
};
