export default [
	{
		id: 0,
		name: "Kitchen Wilmette",
		linkLarge: "./images/carousel/IMG_0820.jpg",
		linkMedium: "./images/carousel/IMG_0820-1024px.jpg",
		linkSmall: "./images/carousel/IMG_0820-600px.jpg",
	},
	{
		id: 1,
		name: "Bathroom Wilmette",
		linkLarge: "./images/carousel/IMG_0943.jpg",
		linkMedium: "./images/carousel/IMG_0943-1024px.jpg",
		linkSmall: "./images/carousel/IMG_0943-600px.jpg",
	},
	{
		id: 2,
		name: "Bathroom Chicago",
		linkLarge: "./images/carousel/IMG_1968.jpg",
		linkMedium: "./images/carousel/IMG_1968-1024px.jpg",
		linkSmall: "./images/carousel/IMG_1968-600px.jpg",
	},
	{
		id: 3,
		name: "Kitchen Wicker Park",
		linkLarge: "./images/carousel/WickerPark_1.jpg",
		linkMedium: "./images/carousel/WickerPark_1-1024px.jpg",
		linkSmall: "./images/carousel/WickerPark_1-600px.jpg",
	},
	{
		id: 4,
		name: "Master Bath Winnetka",
		linkLarge: "./images/carousel/Winnetka_1.jpg",
		linkMedium: "./images/carousel/Winnetka_1-1024px.jpg",
		linkSmall: "./images/carousel/Winnetka_1-600px.jpg",
	},
	{
		id: 5,
		name: "Bathroom Chicago",
		linkLarge: "./images/carousel/IMG_1979.jpg",
		linkMedium: "./images/carousel/IMG_1979-1024px.jpg",
		linkSmall: "./images/carousel/IMG_1979-600px.jpg",
	},
	{
		id: 6,
		name: "Kitchen Chicago",
		linkLarge: "./images/carousel/IMG_2186.jpg",
		linkMedium: "./images/carousel/IMG_2186-1024px.jpg",
		linkSmall: "./images/carousel/IMG_2186-600px.jpg",
	},
];
