<form target="_blank" rel="noopener noreferrer" action="https://aemail.com/3lR9" class="contact">
    <div>
        <h2>Contact us</h2>
    </div>
    <div class="contact-field">
        <label>
            Subject:
            <input name="subject" type="text" class="contact-text" required/>
        </label>
    </div>
    <div class="contact-field">
        <label>
            Body:
            <textarea name="body" rows="5" class="contact-text" required></textarea>
        </label>
    </div>
    <div class="contact-submit">
        <input type="submit" value="Send" aria-label="Send"/>
    </div>
</form>

<style>
    h2 {
        font-size: 2.5em;
        text-align: center;
    }
    .contact {
        width: 100%;
        margin: 2em;
    }
    .contact-field {
        display: flex;
        flex-direction: column;
        margin: 1em;
    }
    .contact-field label {
        display: flex;
        flex-direction: column;
        padding: 1em 0 0.5em 1em;
    }
    textarea {
        resize: none;
    }
    .contact-text {
        border: 1px solid #f2f2f2;
        border-radius: 5px;
        transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
        padding: 0.5em 1em 0.5em 1em;
    }
    .contact-text:hover {
        border: 1px solid #818181;
    }
    .contact-text:focus,
    .contact-text:active {
        border: 1px solid #e9a825;
    }
    .contact-submit {
        text-align: center;
    }
    .contact-submit input {
        font-size: 1.5em;
        letter-spacing: 1.5px;
        font-weight: 300;
        background: #fff;
        text-transform: uppercase;
        padding: 0.5em 1em;
        border-bottom: 2px solid transparent;
        transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    }
    .contact-submit input:hover,
    .contact-submit input:focus {
        color: #e9a825;
        border-bottom: 2px solid #e9a825;
    }
    @media screen and (max-width: 600px) {
        h2 {
            margin: 0.2em 0;
        }
        .contact-field {
            margin: 0.5em 1em;
        }
    }
    @media screen and (min-width: 2000px) {
        h2 {
            font-size: 4em;
        }
        label {
            font-size: 1.5em;
        }
        .contact-submit {
            font-size: 2em;
        }
    }
</style>