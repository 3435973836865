<script>
    import { flip } from 'svelte/animate';
    import { quadInOut } from "svelte/easing";
    export let images;
</script>

{#each images as image (image.id)}
    <div class="image" key={image.id} animate:flip="{{duration: 1000, easing: quadInOut}}"> 
        <picture>
            <source media="(max-width:600px)" srcset={image.linkSmall} />
            <source media="(max-width:1000px)" srcset={image.linkMedium} />
            <img src={image.linkLarge} srcset="{image.linkSmall} 600w, {image.linkMedium} 1024w, {image.linkLarge} 2048w" alt={image.name} loading={image.id !== Math.floor(images.length/2) ? "lazy": "eager"} id={image.id} />
        </picture>
    </div>
{/each}


<style>
    img {
        width: 100vw;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
    }
    .image {
        width: 100vw;
        height: 100%;
    }
</style>