<script>
    export let link;
    export let name;
</script>

<li class="item">
    <a href={link}>{name}</a>
</li>

<style> 
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        color: black;
	    padding: 0 1em;
	    text-decoration: none;
        transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }
    .item {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
        color: black;
        border-bottom: 2px solid transparent;
        transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
        transform: translateY(-1px);
    }
    .item:hover > a,
    .item:active > a,
    .item:focus > a {
        color: #e9a825;
    }
    .item:hover,
    .item:active,
    .item:focus {
        border-bottom: 2px solid #e9a825;
    }
    @media screen and (max-width: 400px) {
        a {
            padding: 0 0.5em;
        }
    }
</style>