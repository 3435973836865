<script>
    import { push } from "svelte-spa-router";
</script>

<div class="notfound">
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" preserveAspectRatio="xMidYMid meet" height="4em" viewBox="80.26 76.06 553.74 218.42">
        <g transform="translate(0.000000,378.000000) scale(0.100000,-0.100000)" fill="#000" stroke="none">
            <path d="M1974 3006 c-41 -18 -56 -36 -431 -531 -705 -929 -721 -952 -733 -996 -20 -74 1 -139 57 -176 25 -17 70 -18 540 -21 l513 -3 0 -170 c0 -192 7 -221 63 -254 42 -25 133 -27 170 -2 57 37 62 56 65 249 l4 178 76 0 c131 0 187 46 186 150 -1 107 -43 142 -175 148 l-89 4 0 684 0 684 -29 32 c-28 31 -33 33 -108 36 -51 1 -89 -3 -109 -12z m-47 -708 c-4 -112 -7 -319 -7 -460 l0 -258 -350 0 -351 0 30 43 c17 23 121 161 232 307 111 146 253 334 316 418 64 83 120 152 126 152 7 0 8 -65 4 -202z"/>
            <path d="M3292 3010 c-273 -38 -440 -180 -514 -435 -23 -79 -23 -85 -23 -645 0 -518 2 -571 19 -635 62 -236 197 -372 431 -437 74 -20 103 -22 365 -22 265 -1 291 1 367 21 242 67 370 194 435 433 23 84 23 91 23 635 0 544 0 551 -23 635 -65 238 -192 366 -429 431 -69 19 -109 22 -333 25 -140 1 -283 -1 -318 -6z m537 -301 c157 -27 235 -106 261 -260 14 -90 13 -963 -1 -1048 -27 -157 -109 -237 -270 -262 -108 -17 -499 -7 -565 14 -108 35 -170 111 -194 236 -14 75 -13 1000 1 1076 26 142 94 213 234 241 77 16 448 18 534 3z"/>
            <path d="M5835 3006 c-40 -18 -72 -54 -202 -226 -61 -80 -165 -217 -232 -305 -297 -391 -514 -677 -612 -807 -132 -175 -155 -240 -109 -315 43 -70 18 -67 585 -73 l510 -5 2 -168 c1 -185 8 -215 56 -249 40 -28 140 -31 180 -5 52 34 56 50 63 245 l7 182 66 0 c88 0 135 14 165 51 22 26 26 41 26 93 0 114 -48 154 -187 155 l-73 1 0 678 c0 659 -1 678 -20 710 -11 18 -32 37 -46 42 -36 14 -144 12 -179 -4z m-55 -961 l0 -465 -350 0 c-192 0 -350 2 -350 4 0 3 102 139 226 303 125 164 281 371 348 460 66 89 122 162 124 163 1 0 2 -209 2 -465z"/>
        </g>
    </svg>
    <h1>Sorry, the page you requested was not found</h1>
    <button class="image-btn" aria-label="Go Home" on:click={() => push("/")}>
        go home
    </button>
</div>

<style>
    * {
        font-family: 'Nunito', sans-serif;
    }
    h1 {
        font-size: 3em;
        margin-left: 1em;
    }
    svg {
        stroke-width: 40;
    }
    button {
        font-size: 1.5rem;
        user-select: none;
        color: #000;
        border-bottom: 2px solid transparent;
        padding: 0.5em 2em;
        background-color: whitesmoke;
        opacity: 0.5;
        transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    }
    button:hover,
    button:active,
    button:focus {
        opacity: 0.8;
        border-bottom: 2px solid #e9a825;
        color: #e9a825;
    }
    button:active {
        opacity: 1;
    }
    .notfound {
        min-height: calc(100vh - 5em);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    @media screen and (min-width: 2000px) {
        .notfound {
            min-height: calc(100vh - 7em);
        }
    }
</style>