<script>
    let scrollY;
</script>

{#if scrollY <= 125}
    <a href="#about" class="icon" aria-label="scroll to about section">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
        </svg>
    </a>
{/if}

<svelte:window bind:scrollY={scrollY} />

<style>
    .icon {
        height: 5em;
        position: absolute;
        top: calc(100vh - 2.5em);
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    svg {
        height: 30px;
        width: 30px;
        fill: #000;
        animation: upanddown 2s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    }

    @media screen and (max-height: 600px) {
        svg {
            fill: #fff;
        }
    }
    @keyframes upanddown {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(1em);
        }
        100% {
            transform: translateY(0);
        }
    }
</style>