<script>
    const year = new Date().getFullYear();
</script>

<div class="footer">
    <div class="footer-section">
        <a href="https://www.facebook.com/kjflooringcarpentry/" target="_blank" rel="noreferrer noopener" aria-label="Facebook">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <defs>
                    <linearGradient id="linear0" gradientUnits="userSpaceOnUse" x1="-277.375" y1="406.602" x2="-277.375" y2="407.573" gradientTransform="matrix(23.503001,0,0,-23.372438,6531.21624,9526.24896)">
                        <stop offset="0" stop-color="rgb(0%,38.431373%,87.843137%)" />
                        <stop offset="1" stop-color="rgb(9.803922%,68.627451%,100%)" />
                    </linearGradient>
                </defs>
                <g id="surface1">
                    <path id="facebook-path-1" d="M 10.132812 23.671875 C 4.550781 22.675781 0.320312 17.855469 0.320312 12.039062 C 0.320312 5.574219 5.609375 0.289062 12.070312 0.289062 C 18.535156 0.289062 23.824219 5.574219 23.824219 12.039062 C 23.824219 17.855469 19.59375 22.675781 14.007812 23.671875 L 13.363281 23.144531 L 10.777344 23.144531 Z M 10.132812 23.671875 "/>
                    <path id="facebook-path-2" d="M 16.652344 15.328125 L 17.183594 12.039062 L 14.070312 12.039062 L 14.070312 9.746094 C 14.070312 8.808594 14.421875 8.101562 15.832031 8.101562 L 17.359375 8.101562 L 17.359375 5.105469 C 16.535156 4.988281 15.597656 4.871094 14.773438 4.871094 C 12.070312 4.871094 10.191406 6.515625 10.191406 9.453125 L 10.191406 12.039062 L 7.253906 12.039062 L 7.253906 15.328125 L 10.191406 15.328125 L 10.191406 23.613281 C 10.835938 23.730469 11.484375 23.789062 12.128906 23.789062 C 12.777344 23.789062 13.421875 23.730469 14.066406 23.613281 L 14.066406 15.328125 Z M 16.652344 15.328125 "/>
                </g>
            </svg>
        </a>
        <a href="https://www.pinterest.com/kjflooringcarpentry/" target="_blank" rel="noreferrer noopener" aria-label="Pinterest">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g id="surface1">
                    <path id="pinterest-path-1" d="M 11.984375 0.898438 C 5.851562 0.898438 0.882812 5.867188 0.882812 12 C 0.882812 16.699219 3.800781 20.714844 7.933594 22.332031 C 7.832031 21.449219 7.75 20.117188 7.964844 19.148438 C 8.167969 18.285156 9.265625 13.632812 9.265625 13.632812 C 9.265625 13.632812 8.933594 12.964844 8.933594 11.984375 C 8.933594 10.433594 9.832031 9.285156 10.933594 9.285156 C 11.882812 9.285156 12.332031 10 12.332031 10.851562 C 12.332031 11.800781 11.734375 13.234375 11.417969 14.550781 C 11.148438 15.648438 11.964844 16.550781 13.066406 16.550781 C 15.035156 16.550781 16.550781 14.464844 16.550781 11.464844 C 16.550781 8.816406 14.632812 6.949219 11.917969 6.949219 C 8.765625 6.949219 6.898438 9.316406 6.898438 11.765625 C 6.898438 12.714844 7.265625 13.75 7.734375 14.300781 C 7.816406 14.417969 7.832031 14.5 7.816406 14.617188 C 7.734375 14.964844 7.550781 15.714844 7.515625 15.867188 C 7.464844 16.066406 7.351562 16.117188 7.148438 16.015625 C 5.765625 15.367188 4.898438 13.351562 4.898438 11.714844 C 4.898438 8.214844 7.449219 5 12.234375 5 C 16.082031 5 19.066406 7.75 19.066406 11.398438 C 19.066406 15.214844 16.648438 18.300781 13.316406 18.300781 C 12.199219 18.300781 11.132812 17.714844 10.765625 17.035156 C 10.765625 17.035156 10.214844 19.148438 10.082031 19.667969 C 9.832031 20.632812 9.148438 21.832031 8.699219 22.582031 C 9.734375 22.898438 10.832031 23.082031 11.984375 23.082031 C 18.117188 23.082031 23.082031 18.117188 23.082031 11.984375 C 23.082031 5.867188 18.117188 0.898438 11.984375 0.898438 Z M 11.984375 0.898438 "/>
                </g>
            </svg>                
        </a>
    </div>
    <div class="footer-section">
        <a href="#/privacy">Privacy Policy</a>  
        <span>
            &#169; {year} K&J Flooring & Carpentry, Inc.
        </span>
    </div>
        
    
</div>

<style>
    .footer {
        height: 3.5em;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .footer-section {
        height: 100%;
        display: flex;
        align-items: center;
    }
    a,span {
        font-size: 0.8em;
        padding: 0 0.5em;
    }
    a {
        height: 100%;
        transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid transparent;
    }
    a:hover,
    a:active,
    a:focus {
        color: #e9a825;
        border-bottom: 2px solid #e9a825;
    }
    #facebook-path-1 {
        stroke: none;
        fill-rule: nonzero;
        fill: url(#linear0);
    }
    #facebook-path-2 {
        stroke: none;
        fill-rule: nonzero;
        fill: rgb(100%,100%,100%);
        fill-opacity: 1;
    }

    #pinterest-path-1 {
        stroke: none;
        fill-rule: nonzero;
        fill: rgb(74.117647%,3.137255%,10.980392%);
        fill-opacity: 1;
    }
    span {
        margin-right: 0.5em;
    }

    @media screen and (max-width: 400px) {
        .footer-section {
            text-align: center;
        }
    }
</style>