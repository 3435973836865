export default [
	{
		name: "Lake View, Chicago",
		linkName: "LakeView_1-1",
		linkLarge: "./images/portfolio/LakeView_1-1.jpg",
		linkMedium: "./images/portfolio/LakeView_1-1-1024px.jpg",
		linkSmall: "./images/portfolio/LakeView_1-1-600px.jpg",
		orientation: "landscape",
	},
	{
		name: "Lake View, Chicago",
		linkName: "LakeView_1-2",
		linkLarge: "./images/portfolio/LakeView_1-2.jpg",
		linkMedium: "./images/portfolio/LakeView_1-2-1024px.jpg",
		linkSmall: "./images/portfolio/LakeView_1-2-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Lake View, Chicago",
		linkName: "LakeView_1-3",
		linkLarge: "./images/portfolio/LakeView_1-3.jpg",
		linkMedium: "./images/portfolio/LakeView_1-3-1024px.jpg",
		linkSmall: "./images/portfolio/LakeView_1-3-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Lake View, Chicago",
		linkName: "LakeView_2-1",
		linkLarge: "./images/portfolio/LakeView_2-1.jpg",
		linkMedium: "./images/portfolio/LakeView_2-1-1024px.jpg",
		linkSmall: "./images/portfolio/LakeView_2-1-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Lake View, Chicago",
		linkName: "LakeView_2-2",
		linkLarge: "./images/portfolio/LakeView_2-2.jpg",
		linkMedium: "./images/portfolio/LakeView_2-2-1024px.jpg",
		linkSmall: "./images/portfolio/LakeView_2-2-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Lake View East, Chicago",
		linkName: "LakeViewEast_1",
		linkLarge: "./images/portfolio/LakeViewEast_1.jpg",
		linkMedium: "./images/portfolio/LakeViewEast_1-1024px.jpg",
		linkSmall: "./images/portfolio/LakeViewEast_1-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Lake View East, Chicago",
		linkName: "LakeViewEast_2",
		linkLarge: "./images/portfolio/LakeViewEast_2.jpg",
		linkMedium: "./images/portfolio/LakeViewEast_2-1024px.jpg",
		linkSmall: "./images/portfolio/LakeViewEast_2-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Lake View East, Chicago",
		linkName: "LakeViewEast_3",
		linkLarge: "./images/portfolio/LakeViewEast_3.jpg",
		linkMedium: "./images/portfolio/LakeViewEast_3-1024px.jpg",
		linkSmall: "./images/portfolio/LakeViewEast_3-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Lake View East, Chicago",
		linkName: "LakeViewEast_4",
		linkLarge: "./images/portfolio/LakeViewEast_4.jpg",
		linkMedium: "./images/portfolio/LakeViewEast_4-1024px.jpg",
		linkSmall: "./images/portfolio/LakeViewEast_4-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Lake View East, Chicago",
		linkName: "LakeViewEast_5",
		linkLarge: "./images/portfolio/LakeViewEast_5.jpg",
		linkMedium: "./images/portfolio/LakeViewEast_5-1024px.jpg",
		linkSmall: "./images/portfolio/LakeViewEast_5-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Wicker Park, Chicago",
		linkName: "WickerPark_1",
		linkLarge: "./images/portfolio/WickerPark_1.jpg",
		linkMedium: "./images/portfolio/WickerPark_1-1024px.jpg",
		linkSmall: "./images/portfolio/WickerPark_1-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Wicker Park, Chicago",
		linkName: "WickerPark_2-1",
		linkLarge: "./images/portfolio/WickerPark_2-1.jpg",
		linkMedium: "./images/portfolio/WickerPark_2-1-1024px.jpg",
		linkSmall: "./images/portfolio/WickerPark_2-1-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Wicker Park, Chicago",
		linkName: "WickerPark_2-2",
		linkLarge: "./images/portfolio/WickerPark_2-2.jpg",
		linkMedium: "./images/portfolio/WickerPark_2-2-1024px.jpg",
		linkSmall: "./images/portfolio/WickerPark_2-2-600px.jpg",
		orientation: "landscape",
	},
	{
		name: "Wicker Park, Chicago",
		linkName: "WickerPark_2-3",
		linkLarge: "./images/portfolio/WickerPark_2-3.jpg",
		linkMedium: "./images/portfolio/WickerPark_2-3-1024px.jpg",
		linkSmall: "./images/portfolio/WickerPark_2-3-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Wicker Park, Chicago",
		linkName: "WickerPark_2-4",
		linkLarge: "./images/portfolio/WickerPark_2-4.jpg",
		linkMedium: "./images/portfolio/WickerPark_2-4-1024px.jpg",
		linkSmall: "./images/portfolio/WickerPark_2-4-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Wicker Park, Chicago",
		linkName: "WickerPark_2-5",
		linkLarge: "./images/portfolio/WickerPark_2-5.jpg",
		linkMedium: "./images/portfolio/WickerPark_2-5-1024px.jpg",
		linkSmall: "./images/portfolio/WickerPark_2-5-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Wicker Park, Chicago",
		linkName: "WickerPark_2-6",
		linkLarge: "./images/portfolio/WickerPark_2-6.jpg",
		linkMedium: "./images/portfolio/WickerPark_2-6-1024px.jpg",
		linkSmall: "./images/portfolio/WickerPark_2-6-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Winnetka",
		linkName: "Winnetka_1",
		linkLarge: "./images/portfolio/Winnetka_1.jpg",
		linkMedium: "./images/portfolio/Winnetka_1-1024px.jpg",
		linkSmall: "./images/portfolio/Winnetka_1-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Winnetka",
		linkName: "Winnetka_2",
		linkLarge: "./images/portfolio/Winnetka_2.jpg",
		linkMedium: "./images/portfolio/Winnetka_2-1024px.jpg",
		linkSmall: "./images/portfolio/Winnetka_2-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Winnetka",
		linkName: "Winnetka_3",
		linkLarge: "./images/portfolio/Winnetka_3.jpg",
		linkMedium: "./images/portfolio/Winnetka_3-1024px.jpg",
		linkSmall: "./images/portfolio/Winnetka_3-600px.jpg",
		orientation: "portrait",
	},
	{
		name: "Winnetka",
		linkName: "Winnetka_4",
		linkLarge: "./images/portfolio/Winnetka_4.jpg",
		linkMedium: "./images/portfolio/Winnetka_4-1024px.jpg",
		linkSmall: "./images/portfolio/Winnetka_4-600px.jpg",
		orientation: "portrait",
	},
];
