<script>
    import Link from "./Link.svelte";

    let links = [
        {
            name: "Home",
            link: "/",
        },
        {
            name: "Portfolio",
            link: "#/portfolio",
        },
    ];
</script>

<div class="nav">
    <picture>
        <source media="(max-width:1000px)"srcset="logos/KJFULL_logo.svg"/>
        <source media="(min-width:1001px)"srcset="logos/KJFULL.svg"/>
        <img src="logos/KJFULL.svg" alt="K&J Flooring & Carpentry Logo">
    </picture>
    <nav class="nav-nav">
        <ul class="nav-ul">
            {#each links as {name,link}}
                <Link {name} {link} />
            {/each}
        </ul>
    </nav>
</div>

<style>
    img {
        height: 3em;
        padding-left: 1em;
    }
    .nav {
        display: flex;
	    align-items: center;
        justify-content: space-between;
        margin: 0 6em;
        height: 5em;
    }
    .nav-nav {
        display: flex;
	    align-items: center;
        justify-content: center;
        height: 100%;
    }
    .nav-ul {
        height: 100%;
        display: flex;
	    align-items: center;
        justify-content: center;
    }
    @media screen and (max-width: 600px) {
        .nav {
            margin: 0 3em;
        }
    }
    @media screen and (max-width: 500px) {
        .nav {
            margin: 0 0.5em;
        }
    }
    @media screen and (min-width: 1550px) {
        .nav-nav {
            font-size: 1.2em;
        }
    }
    @media screen and (min-width: 2000px) {
        .nav {
            height: 7em;
        }
        .nav-nav {
            font-size: 2em;
        }
        img {
            height: 5em;
        }
    }
</style>