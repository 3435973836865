<script>
    import { onDestroy } from "svelte";
    import { fade } from "svelte/transition";       
    import Images from "./Images.svelte";
    import carouselImages from "./carouselImages";
    
    let images = carouselImages;

    let timerIdLeft;
    let timerIdRight;

    let moveLeft = () => {
        document.querySelectorAll(".carousel-svg").forEach((el) => el.disabled = true);
        let imageRotated = images[images.length-1];
        document.getElementById(imageRotated.id).style.opacity= 0;
        setTimeout(() => {
            document.querySelectorAll(".carousel-svg").forEach((el) => el.disabled = false);
        },1000);
        timerIdLeft = setTimeout(() => {
            document.getElementById(imageRotated.id).style.opacity = 1;
        },2500);
        images = [images[images.length-1], ...images.slice(0,images.length-1)];
    }

    let moveRight = () => {
        document.querySelectorAll(".carousel-svg").forEach((el) => el.disabled = true);
        let imageRotated = images[0];
        document.getElementById(imageRotated.id).style.opacity = 0;
        setTimeout(() => {
            document.querySelectorAll(".carousel-svg").forEach((el) => el.disabled = false);
        },1000);
        timerIdRight = setTimeout(() => {
            document.getElementById(imageRotated.id).style.opacity = 1;
        },2500);
        images = [...images.slice(1,images.length),images[0]];
    }

    let timedClick = setInterval(() => {
        moveRight();
    },5000);

    function goLeft() {
        moveLeft();
        stopInterval();
    }

    function goRight() {
        moveRight();
        stopInterval();
    }

    let stopInterval = () => {
        clearInterval(timedClick);
    };

    onDestroy(() => {
        clearInterval(timedClick);
        clearTimeout(timerIdLeft);
        clearTimeout(timerIdRight);
    });
</script>

<div class="carousel">
    <Images {images} />
</div>
<div class="carousel-title">
    <h1>
        Get K&J for your flooring and remodeling projects
    </h1>
</div>
<div class="carousel-nav" in:fade="{{duration: 1000, delay: 1000}}">
    <button class="carousel-svg" aria-label="previous" on:click={() => goLeft()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" >
            <polygon points="15.293 3.293 6.586 12 15.293 20.707 16.707 19.293 9.414 12 16.707 4.707 15.293 3.293"/>
        </svg>
    </button>
    <button class="carousel-svg" aria-label="next" on:click={() => goRight()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24">
            <polygon points="7.293 4.707 14.586 12 7.293 19.293 8.707 20.707 17.414 12 8.707 3.293 7.293 4.707"/>
        </svg>
    </button>
</div>

<style>
    svg {
        fill: whitesmoke;
        opacity: 0.5;
        transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    }
    .carousel {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        overflow: hidden;
        filter: brightness(70%);
    }
    .carousel-nav {
        position: absolute;
        top: 5em;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
    }
    .carousel,
    .carousel-nav,
    .carousel-title {
        height: calc(100vh - 10em);
        width: 100%;
    }
    .carousel-svg {
        cursor: pointer;
        background: transparent;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .carousel-svg:hover svg {
        opacity: 1;
    }
    .carousel-svg:disabled {
        pointer-events: none;
    }

    .carousel-title {
        color: #fff;
        position: absolute;
        top: 5em;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        text-transform: uppercase;
    }

    h1 {
        font-size: 2.75em;
        margin: 0 120px;
        text-align: center;
    }
    @media screen and (min-width: 2000px) {
        .carousel,
        .carousel-nav,
        .carousel-title {
            height: calc(100vh - 14em);
        }
        .carousel-nav,
        .carousel-title {
            top: 7em;
        }

        h1 {
            font-size: 3.5em;
            margin: 0 130px;
        }
        
        svg {
            height: 125px;
            width: 125px;
        }
    }

    @media screen and (max-width: 1000px) {
        h1 {
            font-size: 2em;
        }
    }

    @media screen and (max-width: 600px) {
        h1 {
            font-size: 1.5em;
            margin: 0 80px;
        }

        svg {
            height: 75px;
            width: 75px;
        }
    }
    @media screen and (max-height: 600px) {
        .carousel,
        .carousel-nav,
        .carousel-title {
            height: calc(100vh - 5em);
        }
    }
    </style>