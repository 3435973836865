<script>
    import Form from "./Form.svelte";
    import Footer from "./Footer.svelte";
    import { fade } from "svelte/transition"
</script>

<div class="about flex" id="about" in:fade="{{duration: 500}}">
    <div class="about-information">
        <p>
            K&J Flooring & Carpentry, Inc. started up in 1997. In the beginning, K&J specialized in wood flooring. Throughout the years, advancing the areas of specialization to:
        </p>
        <ul>
            <li>Wood Flooring and Tiles</li>
            <li>Interior Painting</li>
            <li>Kitchen Remodeling</li>
            <li>Bathroom Remodeling</li>
            <li>Basement Remodeling</li>
            <li>Room Additions</li>
        </ul>
        <p>
            K&J has completed work ranging from small to full home remodels and additions. All in the Chicagoland area.
        </p>
    </div>
    <div class="about-contact">
        <Form />
        <p>
            President: Chris Krzanowski
        </p>
        <p>
            Cell : 1 (847) 361 5893
        </p>
    </div>
</div>
<Footer />

<style>
    ul {
        margin: 2em;
        list-style: square inside;
    }
    .about {
        min-height: calc(100vh - 5em - 3.5em);
        font-family: 'Nunito', sans-serif;
        margin-top: 5em;
    }
    .about > div {
        width: 50vw;
        margin: 0 2em;
    }
    .about-contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .flex {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    @media screen and (max-width: 600px) {
        .about {
            width: 100%
        }
        p {
            margin: 0.5em;
        }
        ul {
            margin: 1em 3em;
        }
    }
    @media screen and (max-width: 900px) {
        .about > div {
            width: 100%;
            margin: 2em 1em;
        }
        .flex {
            flex-direction: column;
        }
    }
    @media screen and (min-width: 2000px) {
        .about {
            min-height: calc(100vh - 7em);
        }
        .about-information,
        .about-contact p {
            font-size: 1.5em;
        }
    }
</style>