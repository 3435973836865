export default [
	{
		location: "Lake View, Chicago",
		image: "./images/portfolio/LakeView_1-1.jpg",
		description: "Remodeling Master and Kids Bathroom",
	},
	{
		location: "Lake View East, Chicago",
		image: "./images/portfolio/LakeViewEast_3.jpg",
		description: "Remodeling Master Bathroom",
	},
	{
		location: "Wicker Park, Chicago",
		image: "./images/portfolio/WickerPark_1.jpg",
		description: "Remodeling Kitchen and Master Bathroom",
	},
	{
		location: "Winnetka",
		image: "./images/portfolio/Winnetka_1.jpg",
		description: "Remodeling Master Bathroom",
	},
];
