<script>
    import RadioButton from "./RadioButton.svelte";
    import images from "./images";
    import projects from "./projects";
    import { fade } from "svelte/transition";
    import { view } from "./store/view";
</script>

<RadioButton />
{#if $view === "project"}
    <div class="projects" in:fade="{{duration: 500}}">
        {#each projects as project (project.id)}
            <div class="project-description">
                <h1>
                    {project.location}
                </h1>
                <p>
                    {project.description}
                </p>
            </div>
            <div class="project">
                {#each images as image}
                    {#if image.name === project.location}
                        <figure class="project-item project-{image.orientation}">
                            <picture>
                                <source media="(max-width:1800px)" srcset={image.linkSmall} />
                                <source media="(max-width:3100px)" srcset={image.linkMedium} />
                                <img src={image.linkLarge} srcset="{image.linkSmall} 600w, {image.linkMedium} 1024w, {image.linkLarge} 2048w" alt={image.linkName} loading={project.id > 0 ? "lazy" : "eager"} aria-label="image" />
                            </picture>
                            <a href="#/images/{image.linkName}.jpg" class="project-btn" aria-label="{image.linkName}.jpg">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.904 192.904" height="40" width="40">
                                    <g>
                                        <path d="M190.707,180.101l-47.078-47.077c11.702-14.072,18.752-32.142,18.752-51.831C162.381,36.423,125.959,0,81.191,0
                                            C36.422,0,0,36.423,0,81.193c0,44.767,36.422,81.187,81.191,81.187c19.688,0,37.759-7.049,51.831-18.751l47.079,47.078
                                            c1.464,1.465,3.384,2.197,5.303,2.197c1.919,0,3.839-0.732,5.304-2.197C193.637,187.778,193.637,183.03,190.707,180.101z
                                            M15,81.193C15,44.694,44.693,15,81.191,15c36.497,0,66.189,29.694,66.189,66.193c0,36.496-29.692,66.187-66.189,66.187
                                            C44.693,147.38,15,117.689,15,81.193z"/>
                                        <path d="M118.035,73.689H88.69V44.345c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v29.345H44.346c-4.143,0-7.5,3.358-7.5,7.5
                                            c0,4.142,3.357,7.5,7.5,7.5H73.69v29.346c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5V88.689h29.345c4.143,0,7.5-3.358,7.5-7.5
                                            C125.535,77.047,122.178,73.689,118.035,73.689z"/>
                                    </g>
                                </svg>
                            </a>
                        </figure>
                    {/if}
                {/each}
            </div>
            
        {/each}
    </div>
{/if}
{#if $view === "gallery"}
    <div class="gallery" in:fade="{{duration: 500}}">
        {#each images as image}
            <figure class="gallery-item gallery-{image.orientation}">
                <picture>
                    <source media="(max-width:1800px)" srcset={image.linkSmall} />
                    <source media="(max-width:3100px)" srcset={image.linkMedium} />
                    <img src={image.linkLarge} srcset="{image.linkSmall} 600w, {image.linkMedium} 1024w, {image.linkLarge} 2048w" alt={image.linkName} loading={image.id > 4 ? "lazy" : "eager"} aria-label="image"/>
                </picture>
                <a href="#/images/{image.linkName}.jpg" class="gallery-btn" aria-label="{image.linkName}.jpg">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.904 192.904" height="40" width="40">
                        <g>
                            <path d="M190.707,180.101l-47.078-47.077c11.702-14.072,18.752-32.142,18.752-51.831C162.381,36.423,125.959,0,81.191,0
                                C36.422,0,0,36.423,0,81.193c0,44.767,36.422,81.187,81.191,81.187c19.688,0,37.759-7.049,51.831-18.751l47.079,47.078
                                c1.464,1.465,3.384,2.197,5.303,2.197c1.919,0,3.839-0.732,5.304-2.197C193.637,187.778,193.637,183.03,190.707,180.101z
                                M15,81.193C15,44.694,44.693,15,81.191,15c36.497,0,66.189,29.694,66.189,66.193c0,36.496-29.692,66.187-66.189,66.187
                                C44.693,147.38,15,117.689,15,81.193z"/>
                            <path d="M118.035,73.689H88.69V44.345c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v29.345H44.346c-4.143,0-7.5,3.358-7.5,7.5
                                c0,4.142,3.357,7.5,7.5,7.5H73.69v29.346c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5V88.689h29.345c4.143,0,7.5-3.358,7.5-7.5
                                C125.535,77.047,122.178,73.689,118.035,73.689z"/>
                        </g>
                    </svg>
                </a>
            </figure>
        {/each}
    </div>
{/if}


<style>
    .project,
    .gallery {
        padding: 0.5em;
    }
    .projects,
    .gallery {
        font-family: 'Nunito', sans-serif;
    }
    /* Project styling */
    .project {
        display: grid;
        grid-template-columns: repeat(5,1fr);
        grid-gap: 0.5em;
    }
    .project-landscape {
        grid-column-end: span 2;
    }
    .project-item {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .project-item:hover img,
    .project-item:active img,
    .project-item:focus img {
        transform: scale(1.25);
        filter: brightness(65%);
    }
    .project-item:hover svg,
    .project-item:active svg,
    .project-item:focus svg {
        opacity: 1;
        transform: translate(-50%,-50%) scale(1);
    }
    .project-description {
        margin: 0 2em;
    }
    .project-btn {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .project img {
        min-height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }

    /* Gallery styling */
    .gallery {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 0.5em;
    }
    .gallery-item {
        position: relative;
        overflow: hidden;
    }
    .gallery-landscape {
        grid-column-end: span 2;
    }
    .gallery-item:hover img,
    .gallery-item:active img,
    .gallery-item:focus img {
        transform: scale(1.25);
        filter: brightness(65%);
    }
    .gallery-item:hover svg,
    .gallery-item:active svg,
    .gallery-item:focus svg {
        opacity: 1;
        transform: translate(-50%,-50%) scale(1);
    }
    .gallery-btn {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .gallery img {
        min-height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }
    svg {
        fill: whitesmoke;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) scale(0.75);
        opacity: 0;
        transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }

    @media screen and (max-width: 1000px) {
        .project,
        .gallery {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media screen and (max-width: 600px) {
        .project,
        .gallery {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media screen and (max-width: 500px) {
        .project,
        .gallery {
            grid-template-columns: auto;
        }
    }
    @media screen and (min-width: 2000px) {
        h1 {
            font-size: 3em;
        }
        p {
            font-size: 2em;
        }
        svg {
            height: 80px;
            width: 80px;
        }
        .project,
        .gallery {
            grid-gap: 1em;
            padding: 1em;
        }
    }
    @media screen and (min-width: 2500px) {
        .project,
        .gallery {
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 1.5em;
            padding: 1.5em;
        }
    }
</style>
