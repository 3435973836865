<script>
	import Navbar from "./Navbar.svelte";
	import Router from "svelte-spa-router";
	import routes from './routes'

</script>

<main>
	<Navbar/>
	<Router {routes} />
</main>

