<script>
    import images from "./images";
    import { push } from "svelte-spa-router";
    import { fade } from "svelte/transition";
    import { view } from "./store/view";

    export let params = {};

    let wordsRegex = /($[a-z])|[A-Z][^A-Z]+/g;
	let location = params.link.split("_")[0].match(wordsRegex).join(" ");
    
    let filteredImages = images.filter((obj) => {
        return obj.name.split(",")[0] === location;
    });
    let filteredIndex = filteredImages.findIndex((el) => {
        return el.linkLarge === `./images/portfolio/${params.link}`;
    });
    let index = images.findIndex((el) => {
        return el.linkLarge === `./images/portfolio/${params.link}`;
    });

    const handleKeydown = (e) => {
        //escape key
        if(e.keyCode === 27) push("/portfolio");
        //space
        if(e.keyCode === 32) nextImage();
        //left arrow
        if(e.keyCode === 37) previousImage();
        //right arrow
        if(e.keyCode === 39) nextImage();
    };

    const previousImage = () => {
        if(filteredIndex - 1 >= 0 && filteredIndex - 1 <= filteredImages.length - 1 && $view === "project") {
            filteredIndex--;
        };
        if(index - 1 >= 0 && index - 1 <= images.length - 1 && $view === "gallery") {
            index--;
        };
    };
    const nextImage = () => {
        if(filteredIndex + 1 >= 0 && filteredIndex + 1 <= filteredImages.length - 1 && $view === "project") {
            filteredIndex++;
        };
        if(index + 1 >= 0 && index + 1 <= images.length - 1 && $view === "gallery") {
            index++;
        };
    };
</script>

<svelte:window on:keydown={handleKeydown}/>

<div class="image" in:fade="{{duration: 500}}">
    {#if $view === "project"}
        {#each filteredImages as image, i}
            {#if filteredIndex === i}
                <picture class={image.orientation}>
                    {#if image.orientation === "landscape"}
                        <source media="(max-width:600px)" srcset={image.linkSmall} />
                        <source media="(max-width:1000px)" srcset={image.linkMedium} />
                    {:else}
                        <source media="(max-height:600px)" srcset={image.linkSmall} />
                        <source media="(max-height:1000px)" srcset={image.linkMedium} />
                    {/if}
                    <img src={image.linkLarge} alt={location} id={image.linkLarge} class={image.orientation} loading="lazy" transition:fade={{duration: 500}} />
                </picture>
            {/if}
        {/each}
    {/if}
    {#if $view === "gallery"}
        {#each images as image, i}
            {#if index === i}
                <picture class={image.orientation}>
                    {#if image.orientation === "landscape"}
                        <source media="(max-width:600px)" srcset={image.linkSmall} />
                        <source media="(max-width:1000px)" srcset={image.linkMedium} />
                    {:else}
                        <source media="(max-height:600px)" srcset={image.linkSmall} />
                        <source media="(max-height:1000px)" srcset={image.linkMedium} />
                    {/if}
                    <img src={image.linkLarge} alt={location} id={image.linkLarge} class={image.orientation} loading="lazy" transition:fade={{duration: 500}} />
                </picture>
            {/if}
        {/each}
    {/if}
    <div class="image-nav" id="image-nav" in:fade="{{duration: 1000, delay: 1000}}">
        <div class="image-svg" role="button" alt="previous" aria-label="previous" id="left-div" on:click={() => {previousImage()}}>
            {#if ($view === "project" && filteredIndex > 0) || ($view === "gallery" && index > 0)}
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" in:fade={{duration:500}} out:fade={{duration:500}}>
                    <polygon points="15.293 3.293 6.586 12 15.293 20.707 16.707 19.293 9.414 12 16.707 4.707 15.293 3.293"/>
                </svg>
            {/if}
        </div>
        <div class="image-svg" role="button" alt="next" aria-label="previous" id="right-div" on:click={() => {nextImage()}}>
            {#if ($view === "project" && filteredIndex < filteredImages.length - 1) || ($view === "gallery" && index < images.length - 1)}
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" in:fade={{duration:500}} out:fade={{duration:500}}>
                    <polygon points="7.293 4.707 14.586 12 7.293 19.293 8.707 20.707 17.414 12 8.707 3.293 7.293 4.707"/>
                </svg>
            {/if}
        </div>
    </div>
    <button class="image-btn" aria-label="Go Back" on:click={() => {push("/portfolio")}}>
        go back
    </button>
</div>

<style>
    * {
        font-family: "Nunito", sans-serif;
    }
    svg {
        fill: #000;
    }
    .image {
        user-select: none;
        position: relative;
        height: calc(100vh - 5em);
        min-width: 100vw;
        overflow: hidden;
    }
    .image-nav {
        position: absolute;
        height: 100%;
        width: 100vw;
        display: flex;
        justify-content: space-between;
    }
    .image-svg {
        cursor: pointer;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 100%;
    }
    button {
        user-select: none;
        position: fixed;
        bottom: 5vh;
        left: 50%;
        transform: translate(-50%,-50%);
        border: 1px solid transparent;
        border-radius: 5px;
        padding: 0.5em 2em;
        background-color: whitesmoke;
        opacity: 0.5;
        transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    }
    button:hover,
    button:active,
    button:focus {
        opacity: 0.8;
        border: 1px solid #e9a825;
        color: #e9a825;
    }
    button:active {
        opacity: 1;
    }
    @media screen and (max-width: 600px) {
        img {
            width: 100%;
            height: auto;
        }
        picture[class^="portrait "] ~ .image-nav svg {
            fill: #fff;
        }
    }
    @media screen and (max-width: 1023px) {
        picture[class^="landscape "] ~ .image-nav svg {
            fill: #fff;
        }
    }
    @media screen and (min-width: 1500px) {
        .image-btn {
            font-size: 1.2em;
        }
    }
    @media screen and (min-width: 2000px) {
        .image {
            height: calc(100vh - 7em);
        }
        .image-svg svg {
            width: 10em;
            height: 10em;
        }
        .image-btn {
            font-size: 1.5em;
        }
    }
</style>