<script>
    import { fade } from "svelte/transition";
    import { view } from "./store/view";

    let value = $view;

    const updateView = (e) => {
        view.update(() => {
            return e.target.value;
        });
    }

</script>

<form class="select" in:fade="{{duration: 500, delay: 1000}}">
    <label for="view">
        Select view:
    </label>
    <!-- svelte-ignore a11y-no-onchange -->
    <select name="view" id="view" bind:value on:change={updateView}>
        <option value="gallery" class="select-option">as Gallery</option>
        <option value="project" class="select-option">by Project</option>
    </select>
</form>

<style>
    select {
        background-color: transparent;
    }
    .select {
        text-align: end;
        font-family: 'Nunito', sans-serif;
        margin: 1em 5em;
    }
    @media screen and (max-width: 600px) {
        .select {
            margin: 1em 3em;
        }
    }
    @media screen and (max-width: 500px) {
        .select {
            margin: 1em 1.5em;
        }
    }
    @media screen and (min-width: 2000px) {
        select {
            font-size: 2em;
        }
        .select {
            margin: 2em 5em;
        }
        .select label {
            font-size: 2.5em;
        }
        .select-option {
            font-size: 10px;
        }
    }
</style>